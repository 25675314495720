/*
 * Open Sans (sans)
 */

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-MediumItalic.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-Italic.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-Light.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-LightItalic.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-Medium.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/OpenSans/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*
 * Quicksand (sans, important)
 */

@font-face {
  font-family: "Quicksand";
  src: url("../fonts/Quicksand/Quicksand-SemiBold.woff2") format("woff2"),
    url("../fonts/Quicksand/Quicksand-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("../fonts/Quicksand/Quicksand-Bold.woff2") format("woff2"),
    url("../fonts/Quicksand/Quicksand-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("../fonts/Quicksand/Quicksand-Medium.woff2") format("woff2"),
    url("../fonts/Quicksand/Quicksand-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("../fonts/Quicksand/Quicksand-Regular.woff2") format("woff2"),
    url("../fonts/Quicksand/Quicksand-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("../fonts/Quicksand/Quicksand-Light.woff2") format("woff2"),
    url("../fonts/Quicksand/Quicksand-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
