@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "tarteaucitron.css";

@layer base {
  body {
    @apply font-sans text-default-dark;
  }
}

@layer components {
  .prose {
    @apply max-w-none
      text-lg
      prose-h2:font-important
      prose-h2:font-bold
      prose-h2:text-4xl
      prose-h2:mt-8
      prose-h2:mb-8
      prose-h3:font-important
      prose-h3:font-bold
      prose-h3:text-3xl
      prose-h3:mb-8
      prose-h4:font-important
      prose-h4:font-bold
      prose-h4:text-2xl
      prose-h4:mb-8
      prose-p:mb-4
      prose-p:last:mb-0;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }

  .scale-radius {
    mask-image: radial-gradient(white, black);
  }
}

.image-swiper-pagination:not(.image-swiper-pagination-active) {
  background-color: white;
}

.grecaptcha-badge {
  visibility: hidden;
  right: 0 !important;
}
